import { Button } from '@chakra-ui/react';
import styled from '@emotion/styled';

const TransparentButton = styled(Button)`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	border-radius: 27px;
	background: transparent;
	color: #ffffff;

	&:hover {
		background: transparent;
	}
`;

export default TransparentButton;
